<template>
  <details 
    class="ui-nft-attributes" 
    open>
    <summary class="ui-nft-attributes__summary">{{ $t('user-portal.nft_attributes_title') }}</summary>

    <div class="ui-nft-attributes__tabs">
      <div 
        :class="{
          'ui-nft-attributes__tab': true,
          '-active': categ.value === category
        }"
        @click="() => category = categ.value"
        v-for="categ in attributeCategories"
      >{{ categ.label }}</div>
    </div>

    <ul 
      class="ui-nft-attributes__items" 
      v-if="attributes && attributes.length > 0">
      <li  
        class="ui-nft-attributes__item" 
        :key="attribute.name"
        v-for="attribute in attributes">
        <p class="-name">{{ attribute.name }}: </p>
        <component 
          class="-value"
          :is="attribute.tag"
          :href="attribute.tag === 'a' ? getAttributeValue(attribute.value) : null"
          :target="attribute.tag === 'a' ? '_blank' : null"
        >{{ getAttributeValue(attribute.value) }}<span v-if="attribute.maxValue"> / {{ attribute.maxValue }}</span></component>
      </li>
    </ul>
  </details>
</template>

<script>
export default {
  name: 'NftAttributes',

  props: {
    displaySystem: {
      default: false,
      type: Boolean,
    },

    values: {
      type: Array, 
      required: true,
    },
  },

  data() {
    return {
      category: null,
    }
  },

  computed: {
    attributes() {
      let ret = this.attributeCategories.find(categ => categ.value === this.category)
      return ret ? ret.values : []
    },

    attributeCategories() {
      let order = ['string', 'number', 'boost_number', 'system']

      let categ = []

      this.values.forEach(r => {
        if(r.type === 'system' && !this.displaySystem) {
          return
        }

        r.tag = 'p'

        if(r.value.includes('ipfs://')) {
          r.tag = 'a'
        }

        let f = categ.find(cat => cat.value === r.type)

        if(!f) {
          const type = r.displayType ?? 'string'

          f = {
            value: type,
            label: this.$t(`user-portal.nft_attribute_type_${type}`),
            values: [],
            position: order.indexOf(type)
          }

          categ.push(f)
        }

        f.values.push(r)
      })

      categ = categ.sort((a, b) => a.position - b.position)
      return categ
    },
  },

  methods: {
    getAttributeValue(value) {
      let ret = value
      let regex = new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/gm)

      if(value.match(regex)) {
        ret = new Date(value)
        ret = this.$basil.i18n.datetime(ret)
      }

      if(value.includes('ipfs://')) {
        ret = value.replace('ipfs://', 'https://ipfs.sayl.cloud/ipfs/')
      }

      return ret
    }
  },

  mounted() {
    if(this.category == null && this.attributeCategories && this.attributeCategories.length > 0) {
      this.category = this.attributeCategories[0].value
    }
  }
}
</script>